import { Save } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import "../styles/addbutton.css";

import PropTypes from "prop-types";

export const AddButton = ({ onSubmit, title, icon }) => {
  const onClick = () => {
    onSubmit && onSubmit();
  };
  return (
    <div className="add__buton-float" onClick={onClick}>
      <Tooltip title={title} placement="left" arrow>
        {icon}
      </Tooltip>
    </div>
  );
};

AddButton.defaultProps = {
  title: "Guardar",
  icon: <Save sx={{ fontSize: "26px", color: "#FFFFFF" }} />,
};

AddButton.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.any,
  onSubmit: PropTypes.func,
};
