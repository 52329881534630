import {
  Button,
  Collapse,
  Grid,
  Grow,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Help } from '@mui/icons-material';
import { Box } from "@mui/system";
import { useEffect } from "react";
import { ivas } from "../../helpers/data-iva";
import { formatMoney } from "../../helpers/formatMoney";
import { cardListEFR } from "../../helpers/getData";
import { regexEmail, regexNumChart, regexNums, regexRFC, regexTelefono } from "../../helpers/regexs";
import {
  BlueSwitch,
  buttonContactStyle,
  // bttnFactStyleSave,
  buttonFileStyle,
  buttonSmallContactStyle,
  buttonViewPDF,
} from "../../helpers/stylesMaterial";
import { typesSave } from "../../helpers/types";
import { useModalConfirmationEFR } from "../../hooks/useModalConfirmationEFR";
import {
  cleanCadena,
  cleanContactosCadena,
} from "../../slices/cadena/cadenaSlice";
import {
  startLoadDocumentos,
  startLoadProcesos,
} from "../../slices/catalogo/catalgoThunk";
import { EscalonadoForms } from "../cadena/escalonado/EscalonadoForms";
import { Liquidacion } from "../cadena/Liquidacion";
import { EscCantidadForms } from "../escalonadoCantidad/EscCantidadForms";
import { FacturasDetalle } from "../FacturasDetalle";
import { IvaInput } from "../form/IvaInput";
import { SelectInput } from "../form/SelectInput";
import { TextInput } from "../form/TextInput";
import { Loading } from "../Loading";
import { ModalBase } from "./ModalBase";
import { CardConfirmationEFR } from "./modalDetail/CardConfirmation";
import { ConfirmationTitle } from "./modalDetail/ConfirmationTitle";
import { InfoText } from "./modalDetail/InfoText";
import { v4 as uuidv4 } from "uuid";
import { Percent, Person, PersonAddAlt1, RemoveRedEye } from "@mui/icons-material";
import { onFormatCuentaInit } from "../../helpers/formatCuenta";
import moment from "moment";
import {
  DatePicker,
  DesktopDatePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import 'moment/locale/es';
import { Edit } from '@mui/icons-material';
import { SearchInput } from "../form/SearchInput";
import { ConfirmDialog } from "./ConfirmDialog";
import { useAuthPage } from "../../hooks/useAuthPage";
import { useModal } from "../../hooks/useModal";
import { nIdTipoLiquidacion24Hrs } from "../../helpers/forms";

const getTypeText = (id) => `${id}-efr-confirmation`;

const gridStyle = () => ({
  width: "100%",
  padding: "3px",
  marginBottom: "10px",
});

const tipos = [
  { value: "1", text: "Fiscal" },
  { value: "2", text: "Oficina" },
  { value: "3", text: "Sucursal" },
];

export const ModalEFRConfirmacion = () => {
  const {
    bancos,
    bancoSearched,
    ciudades,
    colonias,
    contactosStore,
    direccionSearched,
    documentos,
    emisor,
    estados,
    facturaSelected,
    // formFacturacion,
    initialValuesEditSection,
    isOpenContact,
    integraciones,
    isLoading,
    isOnEditSection,
    isOpenListContact,
    modulesChanged,
    open,
    retenciones,
    viewEscaloandos,
    viewFacturacion,
    confirmEscalonadosChange,
    confirmMensajeTicketChange,

    clikcOpenViewPdf,
    closeModal,
    dispatch,
    editSection,
    loadBancos,
    onBlur,
    onChange,
    onChangeInput,
    onLoadDatosFact,
    onLoadDatosOperativos,
    onLoadTiposLiquidacion,
    // onSaveEdit,
    onSubmitCancelEdit,
    searchEmisor,
    setBanco,
    setDireccionSearched,
    setEmisor,
    setFacturaSelected,
    setIsOnEditSection,
    setModulesChanged,
    setViewEscaloandos,
    setViewFacturacion,
    clickAddContact,
    clickOpenListContact,
    handleCloseConfirmEscalonado,
    handleCloseConfirmMensajeTicket,
    emisoresList,
  } = useModalConfirmationEFR();

  useEffect(() => {
    setDireccionSearched();
    // eslint-disable-next-line
  }, [direccionSearched.colonia]);



  useEffect(() => {
    if (open) {
      searchEmisor();
    }
    if (!open) {
      dispatch(cleanContactosCadena());
      dispatch(cleanCadena());
      setViewEscaloandos({
        operacion: false,
        ticket: false,
      });
      setViewFacturacion({
        comision: false,
        transferencia: false,
        minimo: false,
      });
      setModulesChanged([]);
      setIsOnEditSection(initialValuesEditSection);
    }
    // eslint-disable-next-line
  }, [open]);

  useEffect(() => {
    //onLoadDatosFact();
    //dispatch(startLoadDocumentos());
    //dispatch(startLoadProcesos());
    //loadBancos();
    //onLoadDatosOperativos();
    //onLoadTiposLiquidacion();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setBanco();
    // eslint-disable-next-line
  }, [bancoSearched]);

  useEffect(() => {
    if (!isOpenListContact || !isOpenContact.open) {
      setEmisor({
        ...emisor,
        oContactos: contactosStore,
      });
    }
    // eslint-disable-next-line
  }, [isOpenListContact]);

  const formatDate = (date) => {
    if (date && date[date.length - 1] === "Z") {
      return date.slice(0, -1);
    } else {
      return date;
    }
  };

  return (
    <>
      <ConfirmDialog
        isOpenDialog={confirmEscalonadosChange}
        onCloseDialog={handleCloseConfirmEscalonado}
        onConfimDialog={() => editSection({ section: 'datosOperativos', saveSection: true })}
        message={`Se hará un cambio directo a la comisión vigente, esto tiene impacto en algunos procesos. ¿Estas seguro/a de que quieres continuar?`}
        textButtonConfirm="Sí, si estoy seguro/a."
      />
      <ConfirmDialog
        isOpenDialog={confirmMensajeTicketChange}
        onCloseDialog={handleCloseConfirmEscalonado}
        onConfimDialog={() => editSection({ section: 'ticket', saveSection: true })}
        message={`Se hará un cambio a la leyenda del ticket. ¿Estas seguro/a de que quieres continuar?`}
        textButtonConfirm="Sí, si estoy seguro/a."
      />
      <ModalBase
        open={open}
        title=""
        disableLoading
        style={{ width: "62vw", maxHeight: "90vh" }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"es-mx"}>
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              overflow: "hidden",
            }}
          >
            {isLoading && <Loading inModal={true} />}
            <ConfirmationTitle
              title={emisor.sNombreComercial}
              cardList={cardListEFR}
              id={emisor.nIdCNBV}
              entity={emisor}
              changeStatusFunction={editSection}
            />
            <Box
              sx={{
                whiteSpace: "nonwrap",
              }}
              className="confimacion-container"
            >
              {/* ===================== DATOS GENERALES ================================= */}
              <Grid container spacing={3} sx={{ ...gridStyle() }}>
                <CardConfirmationEFR
                  title="Datos generales"
                  id={getTypeText("datosGenerales")}
                  editName="datosGenerales"
                  isEditSection={isOnEditSection.datosGenerales}
                  setIsEditSection={editSection}
                >
                  {!isOnEditSection.datosGenerales ? (
                    <Grid container spacing={3} item sx={{ ...gridStyle() }}>
                      <InfoText title="Razón Social" text={emisor.sRazonSocial} />
                      <InfoText
                        title="Nombre Comercial"
                        text={emisor.sNombreComercial}
                      />
                      <InfoText title="RFC" text={emisor.sRFC} />
                      <InfoText
                        title="Régimen de capital"
                        text={emisor.sRegimenSocietario}
                      />
                      <InfoText
                        title="Actividad económica"
                        text={emisor.sActividadEconomica}
                      />
                      <InfoText
                        title="Correo"
                        text={emisor.sMail}
                      />
                      <InfoText
                        title="Teléfono"
                        text={emisor.sTelefono}
                      />
                      <InfoText
                        title="Identificador de la CNBV"
                        text={emisor.sIdentificadorCNBV}
                      />

                      <InfoText
                        title="¿Es indirecto?"
                        text={emisor.isIndirecto ? "Sí" : "No"}
                      />
                      {emisor.isIndirecto &&
                        <>
                          <InfoText
                            title="Integrador"
                            text={emisor.sNombreComercialIntegrador}
                          />
                          <InfoText
                            title="Comisión"
                            text={emisor.nPorcentajeComision + "%"}
                          />
                        </>
                      }
                    </Grid>
                  ) : (
                    <Grow in={isOnEditSection.datosGenerales}>
                      <Grid container spacing={3} item sx={{ ...gridStyle() }}>
                        <Grid item md={12} sm={12} xs={12}>
                          <TextInput
                            id="sRazonSocial"
                            name="sRazonSocial"
                            label="Razón Social"
                            value={emisor.sRazonSocial}
                            onChangeRoot={onChangeInput}
                            require
                            size="small"
                          />
                        </Grid>

                        <Grid item md={6} sm={6} xs={12}>
                          <TextInput
                            id="sNombreComercial"
                            name="sNombreComercial"
                            label="Nombre Comercial"
                            value={emisor.sNombreComercial}
                            onChangeRoot={onChangeInput}
                            require
                            size="small"
                          />
                        </Grid>

                        <Grid item md={6} sm={6} xs={12}>
                          <TextInput
                            id="sRFC"
                            name="sRFC"
                            label="RFC"
                            value={emisor.sRFC}
                            onChangeRoot={onChangeInput}
                            require
                            size="small"
                            expresionRegular={regexRFC}
                            caracteres="13"
                            erroMsg="RFC inválido"
                            disabled
                          />
                        </Grid>

                        <Grid item md={6} sm={6} xs={12}>
                          <TextInput
                            id="sRegimenSocietario"
                            name="sRegimenSocietario"
                            label="Régimen de capital"
                            value={emisor.sRegimenSocietario}
                            onChangeRoot={onChangeInput}
                            require
                            size="small"
                          />
                        </Grid>

                        <Grid item md={6} sm={6} xs={12}>
                          <TextInput
                            id="sActividadEconomica"
                            name="sActividadEconomica"
                            label="Actividad económica"
                            value={emisor.sActividadEconomica}
                            onChangeRoot={onChangeInput}
                            require
                            size="small"
                          />
                        </Grid>

                        <Grid item md={6} sm={6} xs={12}>
                          <TextInput
                            id="sMail"
                            name="sMail"
                            label="Correo"
                            value={emisor.sMail}
                            onChangeRoot={onChangeInput}
                            expresionRegular={regexEmail}
                            erroMsg="Correo inválido"
                            require
                            size="small"
                          />
                        </Grid>

                        <Grid item md={6} sm={6} xs={12}>
                          <TextInput
                            id="sTelefono"
                            name="sTelefono"
                            label="Teléfono"
                            value={emisor.sTelefono}
                            onChangeRoot={onChangeInput}
                            caracteres="10"
                            expresionRegular={regexTelefono}
                            erroMsg="Teléfono inválido"
                            require
                            size="small"
                          />
                        </Grid>
                        <Grid item md={12} sm={12} xs={12}>
                          <div className="cadenas__blue-switch" >
                            <p>¿Es indirecto?</p>
                            <BlueSwitch
                              onChange={onChange}
                              inputProps={{ "aria-label": "controlled" }}
                              name="isIndirecto"
                              checked={emisor.isIndirecto}
                              value={emisor.isIndirecto}
                            />
                            <p>{emisor.isIndirecto ? "Sí" : "No"}</p>
                          </div>
                        </Grid>
                        <Grid item md={12} sm={12} xs={12}>
                          <Collapse in={emisor.isIndirecto}>
                            <Grid container spacing={3} item >
                              <Grid item md={6} sm={6} xs={12}>
                                <SearchInput
                                  options={(emisoresList?.rows?.length ? emisoresList.rows : []).filter(em => em.nIdEmisor !== emisor.nIdEmisor && !em.nIdIntegrador)}
                                  size="small"
                                  value={emisor.nIdIntegrador}
                                  name="nIdIntegrador"
                                  label="Integrador"
                                  getOptionLabel="sNombreComercial"
                                  getIndexLabel="nIdEmisor"
                                  onChange={onChange}
                                  onChangeInnerInput={onChange}
                                />
                              </Grid>

                              <Grid item md={6} sm={6} xs={12}>
                                <TextInput
                                  size="small"
                                  id="nPorcentajeComision"
                                  name="nPorcentajeComision"
                                  label="Comisión de integrador"
                                  value={emisor.nPorcentajeComision}
                                  onChangeRoot={onChangeInput}
                                  type="number"
                                  endAdornment={
                                    <InputAdornment position="start">%</InputAdornment>
                                  }
                                />
                              </Grid>
                            </Grid>
                          </Collapse>
                        </Grid>
                      </Grid>
                    </Grow>
                  )}
                </CardConfirmationEFR>
                {/* ===================== DIRECIION FISCAL ================================= */}
                <CardConfirmationEFR
                  title="Dirección fiscal y contactos"
                  id={getTypeText("direccion")}
                  editName="direccion"
                  isEditSection={isOnEditSection.direccion}
                  setIsEditSection={editSection}
                >
                  {!isOnEditSection.direccion ? (
                    <Grid container spacing={3} item sx={{ ...gridStyle() }}>
                      <InfoText title="Calle" text={emisor.oDireccion.sCalle} />
                      <InfoText
                        title="Número Exterior"
                        text={emisor.oDireccion.nNumExterno}
                      />
                      <InfoText
                        title="Número Interior"
                        text={emisor.oDireccion.sNumInterno ? emisor.oDireccion.sNumInterno : "--"}
                      />
                      <InfoText
                        title="Código Postal"
                        text={emisor.oDireccion.nCodigoPostal}
                      />
                      <InfoText
                        title="Estado"
                        text={emisor.oDireccion.sNombreEntidad}
                      />
                      <InfoText
                        title="Ciudad"
                        text={emisor.oDireccion.sNombreMunicipio}
                      />
                      <InfoText
                        title="Colonia"
                        text={emisor.oDireccion.sNombreColonia}
                      />
                      <InfoText
                        title="Tipo"
                        text={emisor.oDireccion.sTipoDireccion}
                      />
                      <InfoText
                        title="Contactos: "
                        text={
                          emisor.oContactos.length > 0
                            ? " " + emisor.oContactos.length
                            : "No hay contactos..."
                        }
                        display={emisor.oContactos.length > 0 ? "row" : "column"}
                        contacts={emisor.oContactos}
                        onClickFunction={clickOpenListContact}
                      />
                    </Grid>
                  ) : (
                    <Grow in={isOnEditSection.direccion}>
                      <Grid container spacing={3} item sx={{ ...gridStyle() }}>
                        <Grid item md={4} sm={6} xs={6}>
                          <TextInput
                            id="sCalle"
                            name="sCalle"
                            label="Calle"
                            value={emisor.oDireccion.sCalle}
                            onChangeRoot={onChangeInput}
                            require
                            size="small"
                          />
                        </Grid>
                        <Grid item md={4} sm={6} xs={6}>
                          <TextInput
                            id="nNumExterno"
                            name="nNumExterno"
                            label="Número Exterior"
                            value={`${emisor.oDireccion.nNumExterno}`}
                            onChangeRoot={onChangeInput}
                            expresionRegular={regexNums}
                            size="small"
                            caracteres="6"
                            erroMsg="Invalido"
                            require
                          />
                        </Grid>
                        <Grid item md={4} sm={6} xs={6}>
                          <TextInput
                            id="sNumInterno"
                            name="sNumInterno"
                            label="Número Interior"
                            value={emisor.oDireccion.sNumInterno}
                            onChangeRoot={onChangeInput}
                            expresionRegular={regexNumChart}
                            caracteres="6"
                            size="small"
                          />
                        </Grid>
                        <Grid item md={4} sm={6} xs={6}>
                          <TextInput
                            id="nCodigoPostal"
                            name="nCodigoPostal"
                            label="Código Postal"
                            value={`${emisor.oDireccion.nCodigoPostal}`}
                            onChangeRoot={onChangeInput}
                            expresionRegular={regexNums}
                            require
                            caracteres="5"
                            size="small"
                          />
                        </Grid>
                        <Grid item md={4} sm={6} xs={6}>
                          <SelectInput
                            id="nIdEntidad"
                            name="nIdEntidad"
                            onChange={onChange}
                            label="Estado"
                            data={estados}
                            value={
                              estados.length > 0
                                ? `${emisor.oDireccion.sNombreEntidad}`
                                : ""
                            }
                            size="small"
                          />
                        </Grid>
                        <Grid item md={4} sm={6} xs={6}>
                          <SelectInput
                            id="sNombreMunicipio"
                            name="sNombreMunicipio"
                            onChange={onChange}
                            label="Ciudad"
                            data={ciudades}
                            value={
                              ciudades.length > 0
                                ? `${emisor.oDireccion.sNombreMunicipio}`
                                : ""
                            }
                            size="small"
                          />
                        </Grid>
                        <Grid item md={4} sm={6} xs={6}>
                          <SelectInput
                            id="nIdColonia"
                            name="nIdColonia"
                            onChange={onChange}
                            label="Colonia"
                            data={colonias}
                            value={
                              colonias.length > 0
                                ? `${emisor.oDireccion.nIdColonia}`
                                : ""
                            }
                            size="small"
                          />
                        </Grid>
                        <Grid item md={4} sm={6} xs={6}>
                          <SelectInput
                            id="nIdTipo"
                            name="nIdTipo"
                            onChange={onChange}
                            label="Tipo"
                            data={tipos}
                            value={`${emisor.oDireccion.nIdTipo}`}
                            size="small"
                          />
                        </Grid>
                        <Grid item md={4} sm={6} xs={6}>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                            }}
                          >
                            <div style={{ width: "48%" }}>
                              <Tooltip title="Contactos" placement="bottom" arrow>
                                <Button
                                  variant="outlined"
                                  size="large"
                                  sx={{ ...buttonContactStyle, height: "40px" }}
                                  onClick={clickOpenListContact}
                                >
                                  <Person />
                                  {contactosStore.length > 0 && (
                                    <p className="cadenas__button-index">
                                      {contactosStore.length <= 10
                                        ? contactosStore.length
                                        : "10+"}
                                    </p>
                                  )}
                                </Button>
                              </Tooltip>
                            </div>
                            <div style={{ width: "48%" }}>
                              <Tooltip
                                title="Nuevo Contacto"
                                placement="bottom"
                                arrow
                              >
                                <Button
                                  variant="outlined"
                                  size="large"
                                  sx={{ ...buttonContactStyle, height: "40px" }}
                                  onClick={clickAddContact}
                                >
                                  <PersonAddAlt1 />
                                </Button>
                              </Tooltip>
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                    </Grow>
                  )}
                </CardConfirmationEFR>
                {/* ===================== DATOS BANCARIOS ================================= */}
                <CardConfirmationEFR
                  title="Datos Bancarios"
                  id={getTypeText("datosBancarios")}
                  editName="datosBancarios"
                  isEditSection={isOnEditSection.datosBancarios}
                  setIsEditSection={editSection}
                >
                  {!isOnEditSection.datosBancarios ? (
                    <Grid container spacing={3} item sx={{ ...gridStyle() }}>
                      <InfoText title="Beneficiario" text={emisor.sBeneficiario} />
                      <InfoText
                        title="CLABE Interbancaria para depósitos de pagos"
                        text={emisor.sClabe}
                      />
                      <InfoText title="Banco" text={emisor.sNombreBanco} />
                      <InfoText
                        title="Referencia numérica"
                        text={emisor.sRefNumerica}
                      />
                      <InfoText
                        title="Referencia alfanumérica"
                        text={emisor.sRefAlfanumerica}
                      />
                    </Grid>
                  ) : (
                    <Grow in={isOnEditSection.datosBancarios}>
                      <Grid container spacing={3} item sx={{ ...gridStyle() }}>
                        <Grid item md={12} sm={12} xs={12}>
                          <TextInput
                            id="sBeneficiario"
                            name="sBeneficiario"
                            label="Beneficiario"
                            value={emisor.sBeneficiario}
                            onChangeRoot={onChangeInput}
                            require
                            size="small"
                          />
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                          <TextField
                            id="sClabe"
                            name="sClabe"
                            label="CLABE Interbancaria para depósitos de pagos"
                            value={emisor.sClabe}
                            onChange={onChange}
                            size="small"
                            variant="outlined"
                            onBlur={onBlur}
                            fullWidth
                          />
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                          <SelectInput
                            id="nIdBanco"
                            name="nIdBanco"
                            onChange={onChange}
                            label="Bancos"
                            data={bancos}
                            value={emisor.nIdBanco ? `${emisor.nIdBanco}` : ""}
                            // require
                            size="small"
                          />
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                          <TextInput
                            id="sRefNumerica"
                            name="sRefNumerica"
                            label="Referencia numérica"
                            value={emisor.sRefNumerica}
                            onChangeRoot={onChangeInput}
                            require
                            size="small"
                          />
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                          <TextInput
                            id="sRefAlfanumerica"
                            name="sRefAlfanumerica"
                            label="Referencia alfanumérica"
                            value={emisor.sRefAlfanumerica}
                            onChangeRoot={onChangeInput}
                            size="small"
                            require
                          />
                        </Grid>
                      </Grid>
                    </Grow>
                  )}
                </CardConfirmationEFR>
                {/* ===================== DATOS OPERATIVOS ===============================*/}
                <CardConfirmationEFR
                  title="Datos operativos"
                  id={getTypeText("datosOperativos")}
                  editName="datosOperativos"
                  isEditSection={isOnEditSection.datosOperativos}
                  setIsEditSection={editSection}
                >
                  {!isOnEditSection.datosOperativos ? (
                    <Grid container spacing={3} item sx={{ ...gridStyle() }}>
                      <InfoText title="Retención" text={emisor.sRetencion} />
                      <InfoText title="Integración" text={emisor.sIntegracion} />
                      <InfoText title="IVA" text={`${emisor.nIVA * 100}%`} />
                      <InfoText
                        title="Costo por transferencia"
                        text={formatMoney(emisor.nCostoTransferencia)}
                      />
                      <InfoText
                        title="Facturación mínima"
                        text={formatMoney(emisor.nFacturacionMinima)}
                      />
                      <InfoText
                        title="Pago escalonado por operación"
                        text={
                          emisor.oEslonadosOperacion.length > 0
                            ? "Ver detalles ..."
                            : "No hay pago escalonados por operación"
                        }
                        escalonados={emisor.oEslonadosOperacion.length > 0}
                        openEscalonados={() =>
                          setViewEscaloandos({
                            ...viewEscaloandos,
                            operacion: !viewEscaloandos.operacion,
                          })
                        }
                      />
                      {/* <InfoText
                      title="Pagó escalonado por ticket"
                      text={
                        emisor.oEscalonadosTicket.length > 0
                          ? "Ver detalles ..."
                          : "No hay pago escalonados por ticket"
                      }
                      escalonados={emisor.oEscalonadosTicket.length > 0}
                      openEscalonados={() =>
                        setViewEscaloandos({
                          ...viewEscaloandos,
                          ticket: !viewEscaloandos.ticket,
                        })
                      }
                    /> */}
                      <InfoText
                        title="Periodo de gracia"
                        text={emisor.dPeriodoGracia && emisor.dPeriodoGracia !== '0000-00-00' ? moment(formatDate(emisor.dPeriodoGracia))
                          .locale("es")
                          .format("MMMM YYYY") : 'Sin periodo de gracia'}
                      />
                      <InfoText
                        title="Fecha de inicio de operaciones"
                        text={moment(formatDate(emisor.dInicioOperaciones)).locale("es").format(
                          "YYYY-MM-DD"
                        )}
                      />
                      <Grid item md={12} sm={12} xs={12}>
                        <EscalonadosTicket
                          escalonados={emisor.oEscalonadosTicket}
                          open={viewEscaloandos.ticket}
                        />
                      </Grid>
                      <Grid item md={12} sm={12} xs={12}>
                        <EscalonadoOperacion
                          escalonados={emisor.oEslonadosOperacion}
                          open={viewEscaloandos.operacion}
                          emisor={emisor}
                        />
                      </Grid>
                    </Grid>
                  ) : (
                    <Grow in={isOnEditSection.datosOperativos}>
                      <Grid container spacing={3} item sx={{ ...gridStyle() }}>
                        <Grid item md={6} sm={6} xs={12}>
                          <SelectInput
                            id="nIdRetencion"
                            name="nIdRetencion"
                            onChange={onChange}
                            label="Retención"
                            data={retenciones}
                            value={
                              retenciones.length > 0 ? `${emisor.nIdRetencion}` : ""
                            }
                            size="small"
                          />
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                          <SelectInput
                            id="nIdIntegracion"
                            name="nIdIntegracion"
                            onChange={onChange}
                            label="Integración"
                            data={integraciones}
                            value={
                              integraciones.length > 0
                                ? `${emisor.nIdIntegracion}`
                                : ""
                            }
                            size="small"
                          />
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                          <SelectInput
                            id="nIdIVA"
                            name="nIdIVA"
                            onChange={onChange}
                            label="IVA"
                            data={ivas}
                            value={`${emisor.nIdIVA}`}
                            size="small"
                          />
                        </Grid>

                        <Grid item md={6} sm={6} xs={12}>
                          <IvaInput
                            id="nCostoTransferencia"
                            name="nCostoTransferencia"
                            label="Costo por transferencia"
                            value={emisor.nCostoTransferencia}
                            onChangeRoot={onChangeInput}
                            variant="outlined"
                            fullWidth
                            type="number"
                            size="small"
                            ivaValue={emisor.nIVA !== "" ? emisor.nIVA * 100 : 0.16}
                            startAdornment={
                              <InputAdornment position="start">$</InputAdornment>
                            }
                          />
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                          <TextInput
                            id="nFacturacionMinima"
                            name="nFacturacionMinima"
                            label="Facturación mínima"
                            value={emisor.nFacturacionMinima}
                            onChangeRoot={onChangeInput}
                            type="number"
                            require
                            size="small"
                          />
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                          <DesktopDatePicker
                            disablePast={!(moment(formatDate(emisor.dInicioOperaciones)).isBefore(moment()))}
                            label="Fecha de inicio de operaciones"
                            inputFormat="YYYY-MM-DD"
                            size="small"
                            renderInput={(params) => <TextField {...params} size="small" fullWidth />}
                            value={formatDate(emisor.dInicioOperaciones)}
                            disabled={moment(formatDate(emisor.dInicioOperaciones)).isBefore(moment())}
                            onChange={(e) =>
                              onChange({
                                target: {
                                  name: "dInicioOperaciones",
                                  value: moment(e['$d']).format('YYYY-MM-DD')
                                }
                              })
                            }
                          />
                        </Grid>


                        <Grid item md={12} sm={12} xs={12}>
                          <div
                            className="cadenas__blue-switch"
                            style={{ marginTop: "15px" }}
                          >
                            <BlueSwitch
                              onChange={onChange}
                              inputProps={{ "aria-label": "controlled" }}
                              name="isActiveMGracia"
                              checked={emisor.isActiveMGracia}
                              disabled={moment(formatDate(emisor.dPeriodoGracia)).isBefore(moment())}
                              value={emisor.isActiveMGracia}
                            />
                            <p>¿Se activará periodo de gracia?</p>
                          </div>
                          <Collapse in={emisor.isActiveMGracia}>
                            <div
                              className="cadenas__inputs-container"
                              style={{ marginTop: "15px" }}
                            >
                              <DatePicker
                                disablePast={!(moment(formatDate(emisor.dPeriodoGracia)).isBefore(moment()))}
                                label="Periodo de gracia"
                                openTo="year"
                                views={["year", "month"]}
                                value={formatDate(emisor.dPeriodoGracia)}
                                disabled={moment(formatDate(emisor.dPeriodoGracia)).isBefore(moment())}
                                onChange={(e) =>
                                  onChange({
                                    target: {
                                      name: "dPeriodoGracia",
                                      value: moment(e['$d']).format('YYYY-MM-DD')
                                    }
                                  })
                                }
                                renderInput={(params) => (
                                  <TextField {...params} fullWidth />
                                )}
                              />
                            </div>
                          </Collapse>
                        </Grid>
                        <Grid item md={12} sm={12} xs={12}>
                          <div
                            className="cadenas__blue-switch"
                            style={{ marginTop: "15px" }}
                          >
                            <BlueSwitch
                              onChange={onChange}
                              inputProps={{ "aria-label": "controlled" }}
                              name="isActiveEscOperacion"
                              checked={emisor.isActiveEscOperacion}
                              value={emisor.isActiveEscOperacion}
                              disabled
                            />
                            <p>¿Se pagará escalonado por operaciones?</p>
                          </div>
                          <Collapse in={emisor.isActiveEscOperacion}>
                            <EscCantidadForms saveIn={typesSave.CADENA} isEditModal={true} />
                          </Collapse>
                        </Grid>

                        {/* <Grid item md={12} sm={12} xs={12}>
                        <div
                          className="cadenas__blue-switch"
                          style={{ marginTop: "15px" }}
                        >
                          <BlueSwitch
                            onChange={onChange}
                            inputProps={{ "aria-label": "controlled" }}
                            name="isActiveEscTicket"
                            checked={emisor.isActiveEscTicket}
                            value={emisor.isActiveEscTicket}
                          />
                          <p>¿Se pagará escalonado por ticket?</p>
                        </div>
                        <Collapse in={emisor.isActiveEscTicket}>
                          <EscalonadoForms saveIn={typesSave.CADENA} />
                        </Collapse>
                      </Grid> */}
                      </Grid>
                    </Grow>
                  )}
                </CardConfirmationEFR>
                {/* ===================== LIQUIDACION CASHIN ===============================*/}
                <CardConfirmationEFR
                  title="Programación liquidación (CASH IN)"
                  id={getTypeText("cashIn")}
                  editName="cashIn"
                  isEditSection={isOnEditSection.cashIn}
                  setIsEditSection={editSection}
                >
                  {!isOnEditSection.cashIn ? (
                    <Grid container spacing={3} item sx={{ ...gridStyle() }}>
                      {emisor.oLiquidacionCashIn.length > 0 ? (
                        <>
                          <InfoText title="Tipo de liquidacion" text={emisor?.liquidacionCashIn?.tipoSelected?.sDescripcion ?? emisor?.liquidacionCashIn?.sTipoLiquidacion} />
                          {emisor?.liquidacionCashIn?.tipo === nIdTipoLiquidacion24Hrs.toString() ? <>
                            <InfoText title="Monto base" text={formatMoney(emisor?.liquidacionCashIn?.nMontoBase)} />
                            <InfoText title="Porcentaje por transacción" text={`${emisor?.liquidacionCashIn?.nPerTransaccion}%`} />
                          </> : <></>}
                          <LiquidacionDias
                            liquidaciones={emisor.oLiquidacionCashIn}
                            key={"liquidacion-cashIn-2"}
                            type="cashIn"
                          />
                        </>

                      ) : (
                        <InfoText
                          title=""
                          text="No hay liquidaciones programadas..."
                          md={12}
                          sm={12}
                        />
                      )}
                    </Grid>
                  ) : (
                    <Grow in={isOnEditSection.cashIn}>
                      <Grid container spacing={3} item sx={{ ...gridStyle() }}>
                        <Grid item md={12} sm={12} xs={12}>
                          <div
                            className="cadenas__blue-switch"
                            style={{ marginTop: "15px" }}
                          >
                            <BlueSwitch
                              onChange={onChange}
                              inputProps={{ "aria-label": "controlled" }}
                              name="isActiveCashIn"
                              checked={emisor.isActiveCashIn}
                              value={emisor.isActiveCashIn}
                            />
                            <p>
                              {emisor.isActiveCashIn
                                ? "Sin CASH IN"
                                : "Con CASH IN"}
                            </p>
                          </div>
                          <Collapse in={!emisor.isActiveCashIn}>
                            <Liquidacion
                              onCadenaChange={setEmisor}
                              cadena={emisor}
                              tipoMovimiento="liquidacionCashIn"
                              key="liquidacionCashInEfrDetail"
                              defaultValues={emisor.liquidacionCashIn}
                            />
                          </Collapse>
                        </Grid>
                      </Grid>
                    </Grow>
                  )}
                </CardConfirmationEFR>
                {/* ===================== LIQUIDACION CASHOUT ===============================*/}
                <CardConfirmationEFR
                  title="Programación liquidación (CASH OUT)"
                  id={getTypeText("cashOut")}
                  editName="cashOut"
                  isEditSection={isOnEditSection.cashOut}
                  setIsEditSection={editSection}
                >
                  {!isOnEditSection.cashOut ? (
                    <Grid container spacing={3} item sx={{ ...gridStyle() }}>
                      {emisor.oLiquidacionCashOut.length > 0 ? (
                        <>
                          <InfoText title="Tipo de liquidacion" text={emisor?.liquidacionCashOut?.tipoSelected?.sDescripcion ?? emisor?.liquidacionCashOut?.sTipoLiquidacion} />
                          {emisor?.liquidacionCashOut?.tipo === nIdTipoLiquidacion24Hrs.toString() ? <>
                            <InfoText title="Monto base" text={formatMoney(emisor?.liquidacionCashOut?.nMontoBase)} />
                            <InfoText title="Porcentaje por transacción" text={`${emisor?.liquidacionCashOut?.nPerTransaccion}%`} />
                          </> : <></>}
                          <LiquidacionDias
                            liquidaciones={emisor.oLiquidacionCashOut}
                            key={"liquidacion-cashOut-2"}
                            type="cashOut"
                          />
                        </>
                      ) : (
                        <InfoText
                          title=""
                          text="No hay liquidaciones programadas..."
                          md={12}
                          sm={12}
                        />
                      )}
                    </Grid>
                  ) : (
                    <Grow in={isOnEditSection.cashOut}>
                      <Grid container spacing={3} item sx={{ ...gridStyle() }}>
                        <Grid item md={12} sm={12} xs={12}>
                          <div
                            className="cadenas__blue-switch"
                            style={{ marginTop: "15px" }}
                          >
                            <BlueSwitch
                              onChange={onChange}
                              inputProps={{ "aria-label": "controlled" }}
                              name="isActiveCashOut"
                              checked={emisor.isActiveCashOut}
                              value={emisor.isActiveCashOut}
                            />
                            <p>
                              {emisor.isActiveCashOut
                                ? "Sin CASH OUT"
                                : "Con CASH OUT"}
                            </p>
                          </div>
                          <Collapse in={!emisor.isActiveCashOut}>
                            <Liquidacion
                              onCadenaChange={setEmisor}
                              cadena={emisor}
                              tipoMovimiento="liquidacionCashOut"
                              key="liquidacionCashOutEfrDetail"
                              defaultValues={emisor.liquidacionCashOut}
                            />
                          </Collapse>
                        </Grid>
                      </Grid>
                    </Grow>
                  )}
                </CardConfirmationEFR>
                {/* ===================== DOCUMENTOS ===============================*/}
                <CardConfirmationEFR
                  title="Documentos"
                  id={getTypeText("documentos")}
                  editName="documentos"
                  isEditSection={isOnEditSection.documentos}
                  setIsEditSection={editSection}
                >
                  {!isOnEditSection.documentos ? (
                    <Grid container spacing={3} item sx={{ ...gridStyle() }}>
                      {emisor.oDocumentos.length > 0 ? (
                        emisor.oDocumentos.map((documento) => (
                          <InfoText
                            title={documento.sNombreDocumento}
                            customText={<>
                              Ver archivo...
                            </>}
                            text={documento.name}
                            keyURL={documento.key}
                            key={uuidv4()}
                          />
                        ))
                      ) : (
                        <InfoText
                          title=""
                          text="No hay documentos..."
                          md={12}
                          sm={12}
                        />
                      )}
                    </Grid>
                  ) : (
                    <Grow in={isOnEditSection.documentos}>
                      <Grid container spacing={3} item sx={{ ...gridStyle() }}>
                        <Grid item md={12} sm={12} xs={12}>
                          {documentos.filter(doc => doc.nIdEstatus === 1).map((doc) => (
                            <RenderDocumento
                              key={doc.value}
                              clickViewPdf={clikcOpenViewPdf}
                              form={emisor}
                              onChange={onChange}
                              documento={doc.text}
                              name={`${doc.value}`}
                              oDocumentos={emisor.oDocumentos}
                            />
                          ))}
                        </Grid>
                      </Grid>
                    </Grow>
                  )}
                </CardConfirmationEFR>
                {/* ===================== FACTURACION ===============================*/}
                <CardConfirmationEFR
                  title="Datos Facturación"
                  id={getTypeText("datosFacturacion")}
                  editName="datosFacturacion"
                  isEditSection={isOnEditSection.datosFacturacion}
                  setIsEditSection={editSection}
                >
                  {!isOnEditSection.datosFacturacion ? (
                    <Grid container spacing={3} item sx={{ ...gridStyle() }}>
                      <InfoText
                        title="Régimen fiscal"
                        text={emisor.sRegimenFiscal}
                        md={12}
                        sm={12}
                        xs={12}
                      />
                      {emisor.oFacturas.map((fac, i) => (
                        <InfoText
                          title={fac.sNombreTipoFactura}
                          text="Ver detalles..."
                          key={`${fac.nIdTipoFactura}-${i}`}
                          escalonados={true}
                          openEscalonados={() => {
                            setFacturaSelected(fac);
                            setViewFacturacion({
                              ...viewFacturacion,
                              comision: facturaSelected === fac ? !viewFacturacion.comision : true,
                            });
                          }}
                        />
                      ))}
                      <Grid item md={12} sm={12} xs={12}>
                        <FacturaDetail
                          factura={facturaSelected}
                          open={viewFacturacion.comision}
                        />
                      </Grid>
                    </Grid>
                  ) : (
                    <Grow in={isOnEditSection.datosFacturacion}>
                      <Grid container spacing={3} item sx={{ ...gridStyle() }}>
                        <Grid item md={12} sm={12} xs={12}>
                          <FacturasDetalle
                            idElement="facturacionesConfirm-efr"
                            viewModal={false}
                            disabledIVA={true}
                            isActiveEdit={true}
                          />
                        </Grid>
                      </Grid>
                    </Grow>
                  )}
                </CardConfirmationEFR>
                {/* ===================== TICKET ===============================*/}

                <CardConfirmationEFR
                   title={
                    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                      <span className="text-emerald-500">Ticket</span> 
                      <HelperTooltip text="Usa el símbolo | al final de cada línea para crear saltos de línea en el texto del ticket. Ejemplo:|Primera línea|Segunda línea|Tercera línea" />
                    </div>
                  }
                  id={getTypeText('ticket')}
                  editName="ticket"
                  isEditSection={isOnEditSection.ticket}
                  setIsEditSection={editSection}
                >

                  {!isOnEditSection.ticket ? (
                    <Grid container spacing={3} item sx={{ ...gridStyle() }}>
                      <InfoText title={"Leyenda Ticket"} text={emisor.sMensajeTicket} md={6} sm={12} xs={12} noHideText />
                      <InfoText title={"Leyenda Ticket corto"} text={emisor.sMensajeTicketCorto} md={6} sm={12} xs={12} noHideText />

                    </Grid>
                  ) : (
                    <Grow in={isOnEditSection.ticket}>
                      <Grid container spacing={3} item sx={{ ...gridStyle() }}>
                        <Grid item md={6} sm={12} xs={12}>
                          <TextInput
                            id="sMensajeTicket"
                            name="sMensajeTicket"
                            label="Leyenda ticket (Máx. 600 caracteres)"
                            value={emisor.sMensajeTicket}
                            onChangeRoot={onChangeInput}
                            minRows={3}
                            require
                            multiline
                            caracteres="600"
                          />
                      </Grid>
                      <Grid item md={6} sm={12} xs={12}>
                          <TextInput
                            id="sMensajeTicketCorto"
                            name="sMensajeTicketCorto"
                            label="Leyenda ticket corto (Máx. 120 caracteres)"
                            value={emisor.sMensajeTicketCorto}
                            onChangeRoot={onChangeInput}
                            minRows={3}
                            require
                            multiline
                            caracteres="120"
                          />
                        </Grid>
                      </Grid>
                    </Grow>
                  )}
                </CardConfirmationEFR>


                {/* ===================== CUENTAS CONTABLES ===============================*/}
                {!emisor.isActiveCashIn &&
                  <CardConfirmationEFR
                    title="Cuentas contables (CASH IN)"
                    id={getTypeText('cuentaContableIn')}
                    editName="cuentaContableIn"
                    isEditSection={isOnEditSection.cuentaContableIn}
                    setIsEditSection={editSection}
                    viewBttnEdit={false}
                  >
                    <Grid container spacing={3} item sx={{ ...gridStyle() }}>
                      {emisor?.oCuentasContables?.map(cuenta => {
                        if (parseInt(cuenta.nFlujoImporte) === 1) {
                          return (
                            <InfoText title={cuenta.sNombre} text={onFormatCuentaInit(cuenta.nCuenta)} />
                          )
                        }
                      })}

                    </Grid>
                  </CardConfirmationEFR>
                }
                {!emisor.isActiveCashOut &&
                  <CardConfirmationEFR
                    title="Cuentas contables (CASH OUT)"
                    id={getTypeText('cuentaContableOut')}
                    editName="cuentaContableOut"
                    isEditSection={isOnEditSection.cuentaContableOut}
                    setIsEditSection={editSection}
                    viewBttnEdit={false}
                  >
                    <Grid container spacing={3} item sx={{ ...gridStyle() }}>
                      {emisor?.oCuentasContables?.map(cuenta => {
                        if (parseInt(cuenta.nFlujoImporte) === 0) {
                          return (
                            <InfoText title={cuenta.sNombre} text={onFormatCuentaInit(cuenta.nCuenta)} />
                          )
                        }
                      })}

                    </Grid>
                  </CardConfirmationEFR>
                }
              </Grid>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "99%",
                justifyContent: "end",
                height: "10%",
                // border: '1px solid blue'
              }}
            >
              <Button
                onClick={() =>
                  modulesChanged.length > 0 ? onSubmitCancelEdit() : closeModal()
                }
                sx={{
                  ...buttonSmallContactStyle,
                  border: "1px solid transparent",
                  width: "15%",
                  height: "30px",
                  transition: "0.3 ease all",
                  "&:hover": {
                    color: "#004F80",
                    border: "1px solid transparent",
                  },
                }}
              >
                {modulesChanged.length > 0 ? "cancelar " : "cerrar"}
              </Button>
            </Box>
          </Box>
        </LocalizationProvider>
      </ModalBase>
    </>
  );
};

const styleTh = {
  color: "#00609C",
  fontSize: "15px",
  fontWeight: "400",
};
const EscalonadosTicket = ({ escalonados = [], open = false }) => {

  return (
    <Collapse in={open}>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          borderBottom: "3px solid #f1f1f1",
          fontSize: "16px",
          color: "#00CD68",
          fontWeight: "500",
        }}
      >
        Escalonado por ticket
      </div>
      <table className="dashboard-table modal">
        <thead>
          <tr>
            <th style={styleTh}>Monto mínimo</th>
            <th style={styleTh}>Monto máximo</th>
            <th style={styleTh}>Comisión monto fijo</th>
            <th style={styleTh}>Comisión porcentaje %</th>
          </tr>
        </thead>
        <tbody>
          {escalonados.map((esc, i) => (
            <tr key={i}>
              <td style={{ color: "#828282", fontSize: "14px" }}>
                {formatMoney(esc.nMontoMin.toString())}
              </td>
              <td style={{ color: "#828282", fontSize: "14px" }}>
                {formatMoney(esc.nMontoMax.toString())}
              </td>
              <td style={{ color: "#828282", fontSize: "14px" }}>
                {formatMoney(esc.nComision.toString())}
              </td>
              <td style={{ color: "#828282", fontSize: "14px" }}>
                {formatMoney(esc.nPorcentajeCom.toString())}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Collapse>
  );
};

const EscalonadoOperacion = ({ escalonados = [], open = false, emisor = {} }) => {

  const { setAllowComponent } = useAuthPage();
  const { openModalComisionesEscalonadas } = useModal();
  return (
    <Collapse in={open}>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: setAllowComponent('verEscalonadas') ? "space-between" : "center",
          borderBottom: "3px solid #f1f1f1",
          fontSize: "16px",
          color: "#00CD68",
          fontWeight: "500",
        }}
      >
        Escalonado por operación
        {setAllowComponent('verEscalonadas') &&
          <Button
            size="small"
            onClick={() => openModalComisionesEscalonadas(emisor, escalonados)}
          >
            Ver más
          </Button>}
      </div>
      <table className="dashboard-table modal">
        <thead>
          <tr>
            <th style={styleTh}>Cantidad mínima</th>
            <th style={styleTh}>Cantidad máxima</th>
            <th style={styleTh}>Comisión</th>
          </tr>
        </thead>
        <tbody>
          {escalonados.map((esc, i) => (
            <tr key={i + "escOperacion"}>
              <td style={{ color: "#828282", fontSize: "14px" }}>
                {esc.nNumOpeMin.toString()}
              </td>
              <td style={{ color: "#828282", fontSize: "14px" }}>
                {esc.nNumOpeMax.toString()}
              </td>
              <td style={{ color: "#828282", fontSize: "14px" }}>
                {esc.nCosto.toString()}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Collapse>
  );
};

const LiquidacionDias = ({ liquidaciones = [], type }) => {
  return (
    <Box sx={{ width: "90%", margin: "0px auto" }}>
      <table className="dashboard-table modal">
        <thead>
          <tr>
            <th style={{ color: "#00609C", fontSize: "15px" }}>Día de corte</th>
            <th style={{ color: "#00609C", fontSize: "15px" }}>
              Se paga el día
            </th>
          </tr>
        </thead>
        <tbody>
          {liquidaciones.map((liq, i) => (
            <tr key={i + type + liq.nDiaPago + liq.nDiaCorte}>
              <td style={{ color: "#828282", fontSize: "14px" }}>
                {liq.corte}
              </td>
              <td style={{ color: "#828282", fontSize: "14px" }}>{liq.pago}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </Box>
  );
};

const FacturaDetail = ({ open, factura }) => {
  const {
    sNombreUsoCFDI,
    sNombreFormaPago,
    sNombreMetodoPago,
    sNombreClaveProductoServicio,
    sNombreUnidad,
    sNombrePeriodoFacturacion,
    nDiasLiquidaFactura,
    sCorreoDestino,
    sNombreTipoFactura,
    sNombreRegimenFiscal,
  } = factura;

  return (
    <Collapse in={open}>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          borderBottom: "3px solid #f1f1f1",
          borderTop: "3px solid #f1f1f1",
          fontSize: "16px",
          color: "#00CD68",
          fontWeight: "500",
          margin: "10px auto",
        }}
      >
        {sNombreTipoFactura}
      </div>
      <Grid container spacing={3} item md={12} sm={12} xs={12}>
        <InfoText title="Uso del CFDI" text={sNombreUsoCFDI} />
        <InfoText title="Forma de pago" text={sNombreFormaPago} />
        <InfoText title="Método de pago" text={sNombreMetodoPago} />
        <InfoText title="Régimen Fiscal" text={sNombreRegimenFiscal} />

        <InfoText
          title="Clave del producto"
          text={sNombreClaveProductoServicio}
        />
        <InfoText title="Clave de unidad" text={sNombreUnidad} />

        <InfoText
          title="Periodicidad de facturación"
          text={sNombrePeriodoFacturacion}
        />
        <InfoText
          title="Días para liquidar factura"
          text={nDiasLiquidaFactura}
        />

        <InfoText title="Correo a enviar la factura" text={sCorreoDestino} />
      </Grid>
    </Collapse>
  );
};

const RenderDocumento = ({ documento, onChange, name, form, clickViewPdf, oDocumentos = [] }) => {

  const currentDocument = oDocumentos.find(document => parseInt(document.id) === parseInt(name))
  const uploadedDocument = form.documentos[name]
  const documentName = uploadedDocument ? uploadedDocument?.name : currentDocument?.name

  return (
    <div className="flex-row space-between">
      <div
        className="cadenas__inputs-container cadenas__inputs-container-doc"
        style={{ width: "100%", marginBottom: "10px" }}
      >
        <Button
          variant="contained"
          component="label"
          sx={buttonFileStyle}
          className="card__btn-file"
        >
          {documento}
        </Button>
        <Typography
          variant="p"
          component="p"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {documentName ? (documentName + (!documentName.includes(".") ? ".pdf" : "")) : "Archivo no cargado"}
        </Typography>

        <div className="flex-row center">
          <RenderButtonPDF
            openPdf={clickViewPdf}
            value={uploadedDocument ? uploadedDocument : currentDocument?.key}
            disabled={!documentName}
          />
          <IconButton
            color={"primary"}
            component="label"
          >
            <Edit />
            <input
              hidden
              accept="application/pdf"
              multiple
              type="file"
              name={name}
              onChange={onChange}
            />
          </IconButton>

        </div>
      </div>
    </div>
  );
};

const RenderButtonPDF = ({ openPdf, value, disabled = false }) => {
  const onClick = async () => {
    await openPdf(value);
  };
  return (
    <>
      {value && value !== "" && (
        <Button
          variant="outlined"
          component="label"
          onClick={onClick}
          sx={buttonViewPDF}
          disabled={disabled}
        >
          <RemoveRedEye />
        </Button>
      )}
    </>
  );
};
const HelperTooltip = ({ text }) => {
  return <Tooltip title={text} placement="right" arrow>
    <Help sx={{ fontSize: 14, color: '#10B981' }} />
  </Tooltip>
}