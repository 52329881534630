import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { startLoadCamposConciliacion, startLoadFormatosArchivos, startLoadLayoutConciliacion } from '../slices/catalogo/catalgoThunk';
import { getConciliacionPersonalizadaCadena, postConciliacionPersonalizadaCadena, putConciliacionPersonalizadaCadena } from '../slices/cadena/cadenaThunk';
import { useModal } from './useModal';
import { openConciliacion } from '../slices/ui/uiSlice';

const initialForm = {
  bNombrePersonalizado: 0,
  sNombreArchivo: "",
  bArchivosSeparados: 0,
  bConDecimales: 0,
  nIdTipoLayoutConciliacion: 0,
  sCaracter: "",
  nIdFormatoArchivo: 0,
  bIncluyeEncabezado: 0,
  bIgnorarEncabezado: 0,
  bIncluyeFooter: 0,
  bIgnorarFooter: 0,
  aCamposConciliacion: "[]"
}

export const useModalConciliacionPersonalizada = () => {
  const {
    camposConciliacion,
    layoutConciliacion,
    formatosArchivos
  } = useSelector((state) => state.catalogo);


  const { closeModalConciliacion, setOpenToast } = useModal();
  const dispatch = useDispatch();

  const [cfgConciliacion, setCfgConciliacion] = useState(initialForm);
  const [campos, setCampos] = useState([]);
  const [backUp, setBackUp] = useState({
    form: {},
    campos: []
  })

  const openModalConciliacion = async (id, sNombreComercial, tipo) => {
    if (camposConciliacion.length === 0) await dispatch(startLoadCamposConciliacion());
    if (layoutConciliacion.length === 0) await dispatch(startLoadLayoutConciliacion());
    if (formatosArchivos.length === 0) await dispatch(startLoadFormatosArchivos());

    const innerForm = await dispatch(getConciliacionPersonalizadaCadena(id));
    const form = {
      ...initialForm,
      ...innerForm,
      nIdActor: id,
      sNombreComercial,
      tipoConciliacion: tipo,
      bConciliacionPersonalizada: !!innerForm.nIdCfgConciliacion,
    }
    const campos = JSON.parse(innerForm.aCamposConciliacion ?? "[]")
    setCfgConciliacion(form);
    setCampos(campos)
    setBackUp({
      form, campos
    })
    dispatch(openConciliacion());
  };

  const onChangeConciliacion = (form) => {
    setCfgConciliacion({
      ...cfgConciliacion,
      ...form,
    });
  }

  const onChangeCampos = (campos) => {
    setCampos(campos);
  }

  const handleSubmitCfgConciliacion = async () => {
    const validateData = validarData()

    if (!validateData.ok) {
      setOpenToast(true, validateData.msg);
      return
    }
    const isEditig = !!cfgConciliacion.nIdCfgConciliacion;
    const response = await dispatch(isEditig ? putConciliacionPersonalizadaCadena({
      ...cfgConciliacion,
      nIdEstatus: cfgConciliacion.bConciliacionPersonalizada ? 1 : 0,
    }, campos) : postConciliacionPersonalizadaCadena(cfgConciliacion, campos))

    setOpenToast(!response.ok, response.msg);
    if (response.ok) dispatch(closeModalConciliacion());
  }

  const validarData = () => {
    if (cfgConciliacion.bConciliacionPersonalizada) {

      const valCampos = getObjectCampos()

      if (!cfgConciliacion.nIdTipoLayoutConciliacion) return { ok: false, msg: "Tipo de layout es requerido" }
      if (!cfgConciliacion.nIdFormatoArchivo) return { ok: false, msg: "Formato de archivo es requerido" }
      if (!cfgConciliacion.sCaracter && !cfgConciliacion.bRango && !cfgConciliacion.bRegistrosSeparados) return { ok: false, msg: "Caracter es requerido" }
      if (!cfgConciliacion.sNombreArchivo && cfgConciliacion.bNombrePersonalizado) return { ok: false, msg: "Nombre de archivo es requerido" }
      if (!cfgConciliacion.aCamposConciliacion) return { ok: false, msg: "Campos de conciliación son requeridos" }

      if (!valCampos.dFecha && !valCampos.dFechaHora) return { ok: false, msg: "Agrega campo de Fecha o Fecha y hora" }
      //if (!valCampos.sTicket) return { ok: false, msg: "Agrega campo de Ticket SofiPay." }
      //if (!valCampos.sAutorizacion) return { ok: false, msg: "Agrega campo de Número de Autorización." }
      if (!valCampos.sReferencia) return { ok: false, msg: "Agrega campo de Referencia SofiPay." }
      if (!valCampos.nMontoOperacion) return { ok: false, msg: "Agrega campo de Monto de la operación." }
      if (!valCampos.sClaveProducto && !cfgConciliacion.bArchivosSeparados) return { ok: false, msg: "Agrega campo de Clave de producto" }


      if (cfgConciliacion.bNombrePersonalizado && cfgConciliacion.bArchivosSeparados && cfgConciliacion.sNombreArchivo.split("${sClaveProductoSocio}").length === 1) return { ok: false, msg: "En archivos separados por producto, se requiere incluir el producto en el nombre del archivo." }

    }

    return { ok: true, msg: "" };
  }

  const getObjectCampos = () => {
    let result = {}

    campos.forEach((campo) => {
      result[campo.sCampoClase] = true
    })

    return result
  }

  return {
    openModalConciliacion,
    cfgConciliacion,
    setCfgConciliacion,
    onChangeConciliacion,
    onChangeCampos,
    handleSubmitCfgConciliacion,
    campos,
    backUp
  };
};
