import { API } from 'aws-amplify';
import { 
  getDataCash,
  getDaysOfWeekPayList, 
  getFacturaByType, 
  getFormatContacts, 
  getFormatEscalonados, 
  getFormatEscCantidad 
} from '../../helpers/getData';
import { loading } from '../ui/uiSlice';
import { nIdTipoLiquidacion24Hrs } from '../../helpers/forms';


/**
 * Realiza el alta de los emisores
 * @author EGSG 2022-10-07 11:19:40
 * @param form 
 * @returns JSON
 */
export const startAltaEmisores = ( form ) => async ( dispatch, getState ) => {
  const { idUsuario } = getState().user;
  const { cashIn, cashOut } = form.cuentasContables;
  const { facturas } = form;

  const body = {
    nIdEmpleado       : idUsuario,
    // **** DATOS GENERALES ****
    // nIdGiro           : form.giro,
    sActividadEconomica: form.sActividadEconomica,
    sNombreComercial   : form.nombreComercial,
    sRazonSocial       : form.razonSocial,
    sRegimenSocietario : form.regimenSocietario,
    sRFC               : form.rfc,
    sMail              : form.sMail,
    sTelefono          : form.sTelefono,
    nIdIdentificador   : form.nIdIdentificador,
    nIdIntegrador      : form.nIdIntegrador,
    nPorcentajeComision: form.nPorcentajeComision,
    sMensajeTicket    : form.sMensajeTicket,
    sMensajeTicketCorto    : form.sMensajeTicketCorto,
    // **** DIRECCION Y CONTACTOS ****
    sCalle            : form.calle,
    sNumInterno       : form.noInterior, // opcional
    nNumExterno       : form.noExterior, 
    nCodigoPostal     : parseInt(form.codigoPostal),
    nIdColonia        : parseInt(form.colonia),
    nIdTipoDireccion  : parseInt(form.tipo),
    contactos         : getFormatContacts(form.contactos),

    // **** BANCARIOS ****
    sBeneficiario     : form.beneficiario,
    sClabe            : form.clabeInterBanc,
    nIdBanco          : form.nIdBanco !== '' ? parseInt(form.banco): '',
    sRefNumerica      : form.refNumerica, // opcional
    sRefAlfaNumerica  : form.refAlfaNumerica, // opcional

    // **** OPERATIVOS ****
    nIdRetencion       : parseInt(form.retencion),
    // nMontoMaximo       : form.montoRecepcion,
    nIdIntegracion     : parseInt(form.ingragracion),
    nIVAOperacion      : form.ivaOperativo,
    nCostoTransferencia: form.costoTransferencia,
    // nMontoBase         : form.montoBase,
    dMesesGracia       : form.nMesesGracia, // Opcional que sea mayor al de incio operaciones
    dInicioOperaciones : form.dInicioOperaciones, // Obligatorio, validar que la fecha sea mayor a la de hoy
    nFacturacionMinima : form.facturacionMinima,
    escalonadoTicket   : getFormatEscalonados(form.escalonadoForms),
    escalonadoOperaciones : getFormatEscCantidad(form.escCantidadForms),
    
    // **** LIQUIDACION CASH IN ****
    liquidacionCashIn  : {
      nIdTipo: parseInt(form.liquidacionCashIn.tipo),
      nMontoBase: parseInt(form.liquidacionCashIn.tipo) === nIdTipoLiquidacion24Hrs ? form.liquidacionCashIn?.nMontoBase : 0,
      nPerTransaccion: parseInt(form.liquidacionCashIn.tipo) === nIdTipoLiquidacion24Hrs ? form.liquidacionCashIn?.nPerTransaccion : 0,
      nDia   : form.liquidacionCashIn?.tipoSelected?.nPorDia === 1 
                ? parseInt(form.liquidacionCashIn.pagoMes)
                : 0,
      semana : form.liquidacionCashIn?.tipoSelected?.nPorDia === 0
                ? getDaysOfWeekPayList(form.liquidacionCashIn)
                : []
    },

    // **** LIQUIDACION CASH OUT ****
    liquidacionCashOut : {
      nIdTipo: parseInt(form.liquidacionCashOut.tipo),
      nMontoBase: parseInt(form.liquidacionCashOut.tipo) === nIdTipoLiquidacion24Hrs ? form.liquidacionCashOut?.nMontoBase : 0,
      nPerTransaccion: parseInt(form.liquidacionCashOut.tipo) === nIdTipoLiquidacion24Hrs ? form.liquidacionCashOut?.nPerTransaccion : 0,
      nDia   : form.liquidacionCashOut?.tipoSelected?.nPorDia === 1 
                ? parseInt(form.liquidacionCashOut.pagoMes)
                : 0,
      semana : form.liquidacionCashOut?.tipoSelected?.nPorDia === 0
                ? getDaysOfWeekPayList(form.liquidacionCashOut)
                : [] 
    },

    // **** DOCUMENTOS ****
    documentos         :  form.documentos ,

    // **** CONTABLES CASH IN ****
    cuentasContablesCashIN: !form.isActiveCashIn ? getDataCash(cashIn) : {},

    // **** CONTABLES CASH OUT ****
    cuentasContablesCashOut: !form.isActiveCashOut ? getDataCash(cashOut) : {},

    // **** FACTURACION ****
    facturas           : {
      nIdIVA        : facturas.iva,
      sRegimenFiscal: facturas.regimenFiscal,
      facturacion   : [
        getFacturaByType(facturas.comision, 1), 
        getFacturaByType(facturas.transferencia, 2), 
        getFacturaByType(facturas.minimo, 3)
      ]
    }          

  }
  const params = {
    headers: {},
response: false,
    body
  }
  let result = { ok: false, msg: '' }

  try {
    dispatch(loading(true));
    const resp = await API.post('emisores', '/emisores/alta', params);

    const { nCodigo, sMensaje } = resp;

    result.ok  = nCodigo === 0;
    result.msg = sMensaje;

  } catch (error) {
    console.log(error);
    console.log('ocurrio un error ', + error)
    result.ok = false;
    result.msg = 'Error al procesar los datos de la EFR' 

  } finally {
    dispatch(loading(false));
    return result;
  }
};

/**
 * Edita un emisor
 * @author EGSG 2022-10-24 11:59:49
 * @param {*} form 
 * @returns JSON
 */

export const startLoadEditEmisor = ( form, typeEdit ) => async ( dispatch, getState ) => {

  const { user, idUsuario } = getState().user;

  const params = {
    headers: {},
response: false,
    body : { 
      token      : user.token,
      nIdEmpleado: idUsuario,
      // sTipo      : typeEdit, 
      ...form,
    }
  }

  let result = { ok: false, msg: '' }
  console.log(params);
  try {
    dispatch(loading(true));
    const resp = await API.put('emisores', `/emisores/${typeEdit}`, params);
    console.log(resp)
    if ( resp.nCodigo === 0 ) {
      const { nCodigo, sMensaje } = resp;
      console.log(nCodigo, sMensaje);
      result.ok  = nCodigo === 0;
      result.msg = sMensaje;

    } else {
      result.ok  = false;
      result.msg = resp?.sMensaje ?? 'Error al editar los datos de la EFR' ;
    }


  } catch (error) {
    console.log(error);
    console.log('ocurrio un error ', + error)
    result.ok = false;
    result.msg = 'Error al editar los datos de la EFR' 

  } finally {
    dispatch(loading(false));
    return result;
  }


} 


export const postGenerarCredenciales = (body) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      body
    }

    let response = {ok: false, msg: "Ocurrió un error, intente más tarde."}
    try {
      dispatch(loading(true));
      const resp = await API.post('emisores', '/credenciales/generar', params);
      response = {
        ok: resp.nCodigo == 0,
        msg: resp.sMensaje
      }
    } catch (error) {
      console.log('ocurrio un error' + error)
    } finally {
      dispatch(loading(false));
    }
    return response

  }
}

export const postEnviarCredenciales = (body) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      body
    }

    let response = {ok: false, msg: "Ocurrió un error, intente más tarde."}
    try {
      dispatch(loading(true));
      const resp = await API.post('emisores', '/credenciales/enviar', params);
      response = {
        ok: resp.nCodigo == 0,
        msg: resp.sMensaje
      }
    } catch (error) {
      console.log('ocurrio un error' + error)
    } finally {
      dispatch(loading(false));
    }
    return response

  }
}


export const getEnvioCredencialesBitacora = (nIdCredencialSFTP = 0) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      queryStringParameters: {nIdCredencialSFTP}
    }

    let data = []
    try {
      dispatch(loading(true));
      const resp = await API.get('emisores', '/credenciales/bitacora', params);
      console.log(resp)
      data = resp
    } catch (error) {
      console.log('ocurrio un error' + error)
    } finally {
      dispatch(loading(false));
    }
    return data

  }
}