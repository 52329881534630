import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { conciliacionForm } from '../helpers/forms';
import {
  startLoadCadenas,
  startLoadEmisores,
  startLoadEstatusConciliacion,
} from "../slices/catalogo/catalgoThunk";
import { borrarUltimaCarga, conciliarArchivo, getArchivosConciliacion, getNumberFilesS3Key, subirArchivoS3, validateConciliacionFile } from '../slices/conciliacion/conciliacionThunk';
import { setDataTable } from '../slices/conciliacion/conciliacionSlice';
import { useModal } from './useModal';
import { closeImportConciliacion, openImportConciliacion } from '../slices/ui/uiSlice';


import { getFacturas, insertFacturasOrdenes, validarFacturas } from '../slices/facturacion/facturacionThunk';
import { closeFacturasOrdenes } from '../slices/ui/uiSlice';
import { signedURL } from '../slices/cadena/cadenaThunk';
import moment from 'moment';
import { saveAs } from 'file-saver';
import { useLocation, useNavigate } from 'react-router-dom';

export const useConciliacionPage = () => {

  const { setOpenToast, isOpenViewDoc, openModalViewDoc, closeModalViewDoc } = useModal();
  const dispatch = useDispatch();
  const scrollDownRef = useRef(null);

  const navigate = useNavigate();

  const { isOpenImportConciliacion } = useSelector((state) => state.ui);

  const [form, setForm] = useState(conciliacionForm);
  const [filtersLoaded, setFiltersLoaded] = useState(false)
  const [isOpenConfirmDialog, setIsOpenConfirmDialog] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [fileData, setFileData] = useState(false)
  const [selectedFile, setSelectedFile] = useState(false)
  const [progress, setProgress] = useState(0);
  const [log, setLog] = useState([]);
  const [processMessage, setProcessMessage] = useState("")

  const {
    emisores,
    cadenas,
    estatusConciliacion,
  } = useSelector((state) => state.catalogo);

  const {
    dataTable,
  } = useSelector((state) => state.conciliacion);

  useEffect(() => {
    if (isOpenImportConciliacion) {
      setActiveStep(0);
      setIsOpenConfirmDialog(false);
      setProgress(0);
      setLog([]);
      setFileData(false);
      setSelectedFile(false);
      setProcessMessage("")
    }
  }, [isOpenImportConciliacion]);


  const loadInInit = async () => {
    await getArchivosData();
  }

  const getFilters = async () => {
    if (!filtersLoaded) {
      if(cadenas?.rows?.length === 0) await dispatch(startLoadCadenas());
      if(emisores?.rows?.length === 0) await dispatch(startLoadEmisores());
      if(estatusConciliacion.length === 0) await dispatch(startLoadEstatusConciliacion());
      setFiltersLoaded(true)
    }
  }

  const getArchivosData = async () => {
    await dispatch(getArchivosConciliacion(form));
  }

  const onChange = (e, name = null, value = null) => {
    const inputName = name !== null ? name : e.target.name;
    const inputValue = value !== null ? value : e.target.value;

    let valueForm = { ...form, [inputName]: inputValue };

    if (inputName === "nActor" && parseInt(inputValue) === 0) {
      valueForm = { ...valueForm, nIdCadena: 0, nIdEmisor: 0, nIdArchivo: 0 };
    }

    setForm(valueForm);
  };

  const handleOpenImport = () => {
    dispatch(openImportConciliacion())
  };


  const handleCloseImport = async (isProcessEnded = false) => {
    dispatch(closeImportConciliacion())
    if (isProcessEnded) {
      await getArchivosData();
    }
  };

  const confirmDelete = () => {
    setLog([]);
    setIsOpenConfirmDialog(true);
  };

  const postFacturasOrdenes = async (data) => {
    await dispatch(insertFacturasOrdenes(data)).then(async (resp) => {
      if (resp.nCodigo === 0) {
        setOpenToast(false, resp.sMensaje);
        dispatch(closeFacturasOrdenes());
        await getArchivosData();
      } else {
        setOpenToast(true, resp.sMensaje);
      }
    })
  }

  const importMethod = async () => {
    setIsOpenConfirmDialog(false);
    setActiveStep(2);


    setProgress(0);
    setProcessMessage("Subiendo archivo: ")
    //Subida de archivo
    const response = await uploadDocument()
    setProgress(50);
    setProcessMessage("Procesando archivo: ")
    if (!response.nError) {
      await importRequest(response.sRuta, selectedFile.name).then((res) => {
        setLog(res)
      })
    } else {
      setLog({
        nCodigo: 1,
        sMensaje: response.sMensaje
      });
    }
    setProgress(100)
    setActiveStep(3);

  }

  const uploadDocument = async () => {
    return new Promise(async (resolve, reject) => {
      const path = `conciliacion/${fileData.nActor === 1 ? "emisor" : "cadena"}/${moment(fileData.header.dFecConciliacion).format("YYYYMMDD")}/${ String(fileData.nActor === 1 ? fileData.header.nIdEmisor : fileData.header.nIdCadena).padStart(5, "0")}`
      const nArchivos = await dispatch(getNumberFilesS3Key(path))
      const fileName = `${nArchivos + 1}_${selectedFile.name}`

      await dispatch(subirArchivoS3(selectedFile, path, fileName)).then(resp => {
        resolve({
          nError: resp.ok ? 0 : 1,
          sMensaje: resp.msg,
          sRuta: resp.key
        })
      })
    })
  }

  const importRequest = async (S3Key, filename) => {
    return new Promise(async (resolve, reject) => {
      await dispatch(validateConciliacionFile(S3Key, filename)).then((res) => {
        resolve(res);
      });
    });
  };

  const handleDrop = (e) => {
    let file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    if (file) {
      if (isExtensionAllowed(file.name)) {

        const reader = new FileReader();
        reader.readAsText(file);
        reader.onload = (e) => {
          let data = e.target.result;
          const dataValidated = validateData(data.trim(), file.name)

          console.log(dataValidated)

          setFileData(dataValidated)
          setSelectedFile(file)
        }

      } else {
        setOpenToast(true, "Extensión de archivo no compatible");
      }

    }
  };

  const validateData = (data, name) => {

    let sMensajeError = []
    const formatedData = formatFileData(data, name)
    const {
      nActor,
      fileHeader,
      fileData,
      fileFooter,
      fileNameData
    } = formatedData
    console.log(formatedData)

    //Filename
    if (fileNameData.length === 4) {
      if (fileNameData[0] !== "SP") sMensajeError.push(" El formato del nombre del archivo es el incorrecto.");
      if (!(moment(fileNameData[1], "YYYYMMDD").isValid())) sMensajeError.push(` El formato de la fecha en el nombre del archivo es el incorrecto.`);
      if (!(fileNameData[2] === "C" || fileNameData[2] === "E")) {
        sMensajeError.push(` El formato del identificador del tipo de archivo es el incorrecto.`);
      } else {
        const identificadorEntidad = fileNameData[3].split(".")[0]
        if (!(parseInt(identificadorEntidad) && identificadorEntidad.length === 5)) sMensajeError.push(` El formato del identificador ${nActor === 1 ? "de la EFR " : "del comisionista"} en el nombre del archivo es el incorrecto.`);
      }
    } else {
      sMensajeError.push(" El formato del nombre del archivo es el incorrecto.");
    }

    //Header
    if (fileHeader.length === 3) {
      if (!parseInt(fileHeader[1])) sMensajeError.push(` El formato del identificador ${nActor === 1 ? "de la EFR " : "del comisionista"} es incorrecto.`);
      if (!(moment(fileHeader[2], "YYYY-MM-DD").isValid())) sMensajeError.push(" El formato de la fecha en el encabezado es el incorrecto.");
      if (!(moment(fileHeader[2], "YYYY-MM-DD").isSame(moment(fileNameData[1], "YYYYMMDD")))) sMensajeError.push(" La fecha del encabezado no corresponde con la del archivo de conciliación.");
    } else {
      sMensajeError.push(" El formato de la cabecera del archivo es incorrecto.");
    }

    //Footer
    if (fileFooter.length === 4) {
      if ([null, undefined].includes(fileFooter[1])) sMensajeError.push(` El formato del total de las operaciones realizadas es incorrecto.`);
      if ([null, undefined].includes(fileFooter[2])) sMensajeError.push(` El formato del importe total de las operaciones es incorrecto.`);
      if ([null, undefined].includes(fileFooter[3])) sMensajeError.push(` El formato del importe total de las comisiones de las operaciones es incorrecto.`);

  } else {
      sMensajeError.push(" El formato del pie del archivo es incorrecto.");
  }

    //Body
    let body = fileData.map((row, key) => {
      let sMensajeErrorRow = []
      let registro = {}
      if (row.length === 9) {
        registro = {
          dFecha: row[1],
          dHora: row[2],
          ...(nActor === 1 ?
            {
              nOperacion: row[3],
              sFolio: row[4]
            } :
            {
              sAutorizacion: row[3],
              sFolio: row[4]
            }
          ),
          sClaveProducto: row[5],
          sLineaPago: row[6],
          nMontoOperacion: row[7],
          nMontoComision: row[8],
        }
        if (!(moment(registro.dFecha, "YYYY-MM-DD").isValid())) sMensajeErrorRow.push(` El formato de la fecha es incorrecto.`);
        if (!(moment(registro.dHora, "HH:mm:ss").isValid())) sMensajeErrorRow.push(` El formato de la hora es incorrecto.`);
        if (registro.sClaveProducto.length !== 5) sMensajeErrorRow.push(` El formato del tipo de operación es incorrecto.`);
        if (registro.sLineaPago.length !== 22) sMensajeErrorRow.push(` El formato de la linea de pago es incorrecto.`);
        if (!parseInt(registro.nMontoOperacion)) sMensajeErrorRow.push(` El formato del monto de la operación es incorrecto.`);
        if (!parseInt(registro.nMontoComision)) sMensajeErrorRow.push(` El formato del monto de la comisión de la operación es incorrecto.`);

        /* if (nActor === 1) {
          if (registro.nOperacion.length !== 11) sMensajeErrorRow.push(` El formato del número de operación es incorrecto.`);
          if (registro.sFolio.length !== 22) sMensajeErrorRow.push(` El formato del folio es incorrecto.`);
        } else {
          if (registro.sAutorizacion.length !== 15) sMensajeErrorRow.push(` El formato de la clave de autorización es incorrecto.`);
          if (registro.sFolio.length !== 11) sMensajeErrorRow.push(` El formato del folio es incorrecto.`);
        } */
      } else {
        sMensajeErrorRow.push(" El formato del registro es incorrecto.");
      }

      return ({
        ...registro,
        fileData: row,
        nError: sMensajeErrorRow.length > 0 ? 1 : 0,
        sMensaje: sMensajeErrorRow.join(" "),
      })
    })

    const nError = sMensajeError.length > 0 ? 1 : 0
    return ({
      nError,
      sMensaje: sMensajeError.join(" "),
      sMensajeError,
      name,
      nActor,
      header: {
        fileData: fileHeader,
        ...(!nError ?
          {
            nIdCadena: nActor === 2 ? parseInt(fileHeader[1]) : null,
            nIdEmisor: nActor === 1 ? parseInt(fileHeader[1]) : null,
            dFecConciliacion: moment(fileHeader[2]).format("YYYY-MM-DD")
          } : {}
        )
      },
      footer: {
        fileData: fileFooter,
        ...(!nError ?
          {
            nOperacionesRecibidas: parseInt(fileFooter[1]),
            nImporteTotal: parseInt(fileFooter[2]),
            nComisionTotal: parseInt(fileFooter[3]),
          } : {}
        )
      },
      body,
      nTotalRegistros: body.length,
      nRegistrosCargados: body.filter((row) => row.nError === 0).length,
      data: data.split("\n"),
      fileNameData
    })
  }

  const formatFileData = (data, name) => {
    const fileNameData = name.split("_")

    const nActor = fileNameData[2] === "C" ? 2 : 1

    let fileHeader = []
    let fileData = []
    let fileFooter = []

    data = data.split("\n")

    data.map((row, key) => {
      row = row?.split("|").map(r => {
        return r.trim()
      })
      switch (row[0]) {
        case "H1":
          fileHeader = row;
          break;
        case "F1":
          fileFooter = row;
          break;
        case "REG":
          fileData.push(row);
          break;
        default:
          break;
      }
    })

    return ({
      nActor,
      fileHeader,
      fileData,
      fileFooter,
      fileNameData
    })
  }

  const isExtensionAllowed = (fileName) => {
    const extension = fileName.split(".").pop();
    return ["txt", "TXT"].includes(extension);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const openTxtFile = async (file, sNombre) => {
    const res = await dispatch(signedURL(file))
    if (res.ok) {
      fetch(res.url)
        .then(res => res.blob())
        .then((blob) => {
          saveAs(blob, sNombre);
        })
    } else {
      setOpenToast(true, res.msg)
    }
  }

  const handleOpenModalViewDoc = async (file, name) => {
    const res = await dispatch(signedURL(file))
    if (res.ok) {
      openModalViewDoc(res.url, 'txt', name)
    } else {
      setOpenToast(true, res.msg)
    }
  }

  const borrarCarga = async (nIdArchivo) => {
    await dispatch(borrarUltimaCarga(nIdArchivo)).then(async (res) => {
      setOpenToast(res.nCodigo !== 0, res.sMensaje)
      if (res.nCodigo === 0) await getArchivosData()
    })
  }

  const conciliar = async (nIdArchivo, sRuta, dFechaConciliacion) => {
    await dispatch(conciliarArchivo(nIdArchivo, sRuta, dFechaConciliacion)).then(async (res) => {
      setOpenToast(res.nCodigo!== 0, res.sMensaje)
      await getArchivosData()
    })
  }

  const conciliarDiferencias = async (nActor, nIdActor, dFecConciliacion) => {
    navigate( `/conciliacion/manual?nActor=${nActor}&nIdActor=${nIdActor}&dFecConciliacion=${dFecConciliacion}`)
  }


  return {
    dataTable,
    loadInInit,
    getFacturas,
    form,
    onChange,
    emisores,
    cadenas,
    estatusConciliacion,
    getArchivosData,
    getFilters,
    handleOpenImport,

    isOpenConfirmDialog,
    setIsOpenConfirmDialog,

    fileData,
    importMethod,
    isOpenImportConciliacion,
    activeStep,
    handleDrop,
    progress,
    log,
    scrollDownRef,
    handleBack,
    confirmDelete,
    handleNext,
    handleCloseImport,
    processMessage,
    selectedFile,
    setSelectedFile,
    setFileData,

    openTxtFile,
    handleOpenModalViewDoc,
    closeModalViewDoc,
    borrarCarga,
    conciliar,
    conciliarDiferencias,
  };
};
