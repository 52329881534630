import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

export const ConfirmDialog = (props) => {
  const {
    isOpenDialog = false,
    onCloseDialog = null,
    onConfimDialog = null,
    message = "",
    textButtonConfirm = "Confirmar",
    textButtonCancel = "Cancelar",
    body = null,
  } = props;

  return (
    <Dialog
      open={isOpenDialog}
      onClose={onCloseDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{message}</DialogTitle>
      {body ? <DialogContent>{body}</DialogContent> : <></>}
      <DialogActions>
        <Button onClick={onCloseDialog}>{textButtonCancel}</Button>
        <Button onClick={onConfimDialog} variant="contained">
          {textButtonConfirm}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
