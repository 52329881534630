import { Badge, Button, Collapse, Grid, Paper, TextField } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { useEffect, useState } from "react";
import RequestQuoteOutlinedIcon from "@mui/icons-material/RequestQuoteOutlined";
import "dayjs/locale/es-mx";
import { SearchInput } from "./form/SearchInput";
import moment from "moment";
import { useModal } from "../hooks/useModal";
import { TextInput } from "./form/TextInput";
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import { BlueSwitch } from "../helpers/stylesMaterial";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import FilterAltIcon from '@mui/icons-material/FilterAlt';

export const ConciliacionManualForm = (props) => {

  const {
    emisores,
    cadenas,
    form,
    onChange,
    onChangeInput,
    getConciliacionManual,
    isFormExpanded = false,
    handleFormExpanded,
  } = props



  const handleSubmit = async () => {
    await getConciliacionManual();
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"es-mx"}>
      <Paper
        elevation={3}
        style={{
          marginBottom: 30,
          overflow: "hidden",
        }}
      >
        <Grid container>
          <Grid
            item
            width={"50px"}
            alignItems={"center"}
            style={{
              background: "#00CD68",
              padding: "15px 15px 0 15px",
              color: "white",
              fontWeight: "bold",
            }}
          >
            <FilterAltIcon />
          </Grid>
          <Grid
            container
            item
            justifyContent={"space-between"}
            alignItems={"center"}
            style={{
              width: "calc(100% - 50px)",
              background: "#00609C",
              padding: 15,
              color: "white",
              fontWeight: "bold",
            }}
          >
            <span>
              Filtros
            </span>
            <Grid
              item
            >
              <Button
                aria-label="expand row"
                size="small"
                variant="container"
                onClick={() => handleFormExpanded(!isFormExpanded)}
                startIcon={isFormExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                sx={{ margin: 0, padding: 0 }}
              >
                {isFormExpanded ? "Cerrar filtros" : "Abrir filtros"}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Collapse in={isFormExpanded} timeout="auto" unmountOnExit sx={{ width: "100%" }}>
          <Grid container spacing={2} style={{ padding: 20 }} alignItems={"center"}>
            <Grid item xs={12} sm={3}>
              <SearchInput
                options={[
                  {
                    id: 0,
                    name: "Todos",
                  },
                  {
                    id: 1,
                    name: "EFR",
                  },
                  {
                    id: 2,
                    name: "Comisionista",
                  },
                ]}
                value={form.nActor}
                name={"nActor"}
                label={"Entidad"}
                getOptionLabel={"name"}
                getIndexLabel={"id"}
                onChange={onChange}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              {
                form.nActor === 1 || form.nActor === '1' ?
                  <SearchInput
                    options={[{ nIdEmisor: 0, sNombreComercial: 'Todos' }, ...emisores.rows]}
                    value={form.nIdEmisor}
                    name={"nIdEmisor"}
                    label={form.nActor ? "Entidad financiera" : ""}
                    getOptionLabel={"sNombreComercial"}
                    getIndexLabel={"nIdEmisor"}
                    disabled={!form.nActor}
                    onChange={onChange}
                  />
                  :
                  <SearchInput
                    options={[{ nIdCadena: 0, sNombreComercial: 'Todos' }, ...cadenas.rows]}
                    value={form.nIdCadena}
                    name={"nIdCadena"}
                    label={form.nActor ? "Comisionista" : ""}
                    getOptionLabel={"sNombreComercial"}
                    getIndexLabel={"nIdCadena"}
                    disabled={!form.nActor}
                    onChange={onChange}
                  />
              }
            </Grid>
            <Grid item xs={12} sm={4}>
              <DesktopDatePicker
                label="Fecha de conciliación"
                inputFormat="YYYY-MM-DD"
                renderInput={(params) => <TextField {...params} fullWidth />}
                value={form.dFecConciliacion}
                onChange={(e) => onChange(null, 'dFecConciliacion', moment(e['$d']).format('YYYY-MM-DD'))}
                disableFuture
              />
            </Grid>
            <Grid item xs={12} sm={2} sx={{ textAlign: "right" }}>
              <Button variant="contained" onClick={handleSubmit}>
                Buscar
              </Button>
            </Grid>

          </Grid>
        </Collapse>
      </Paper>
    </LocalizationProvider>
  );
};