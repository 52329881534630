import {
  AccountBalance,
  AddCard,
  AssignmentInd,
  CreditScore,
  DriveFolderUpload,
  KeyboardDoubleArrowLeft,
  KeyboardDoubleArrowRight,
  LocationOn,
  Receipt,
  ReceiptLong,
  Settings,
} from '@mui/icons-material';

export const getDataById = (id, list = []) => {
  const searched = list.find((val) => val.value.toString() === `${id}`)
  return searched ? searched.text : ''
}

export const existDataByValue = (search, list) => {
  return !!list.find((val) => val.value === `${search}`);
}

export const getFormatContacts = (contacts = []) =>
  contacts.map((contact) => ({
    sNombre: contact.nombre,
    sAPaterno: contact.apellido,
    sAMaterno: contact.segundoApellido,
    sTelefono: contact.telefono,
    sProcesos: contact.proceso.map(proceso => {
      return {
        nIdProceso: proceso.nIdProceso,
        value: proceso.value,
        text: proceso.text
      }
    }),
    sCorreo: contact.email,
    sArea: contact.area,
    sPuesto: contact.puesto,
    sComentario: contact.comentarios,
  }));


export const getFormatEscalonados = (escalonados) => {
  return escalonados.map((escalonado) => {
    const newEscalonado = {
      nIdComisiones: escalonado?.nIdComisiones ?? '',
      sMontoMinimo: escalonado.montoMinimo,
      sMontoMaximo: escalonado.montoMaximo,
      sComMontoFijo: escalonado.comisionMontoFijo,
      sComPorcentaje: escalonado.comisionPorcentaj
    }
    if (!('nIdComisiones' in escalonado)) {
      delete newEscalonado.nIdComisiones
    }
    return newEscalonado;
  });
}

export const getFormatEscCantidad = (escalonados = []) => {
  return escalonados.map((esc) => {
    const newEsc = {
      nIdComisiones: esc?.nIdComisiones ?? '',
      sCantidadMinima: esc.cantidadMinimo,
      sCantidadMaxima: esc.cantidadMaxima,
      sComMontoFijo: esc.comisionMontoFijo,
      nNumOpeMin: esc.cantidadMinimo,
      nNumOpeMax: esc.cantidadMaxima,
      nCosto: esc.comisionMontoFijo,
    }
    if (!('nIdComisiones' in esc)) {
      delete newEsc.nIdComisiones
    }
    return newEsc;
  });
}
export const getDaysOfWeekPayList = (liquidacion) => {
  return !validateEmpty(liquidacion) ? getPayWeekList(liquidacion) : []
}
export const getPayWeekList = (liquidacion) => {
  const daysOfWeek = [
    { nDiaCorte: 0, nDiaPago: liquidacion.lunes },
    { nDiaCorte: 1, nDiaPago: liquidacion.martes },
    { nDiaCorte: 2, nDiaPago: liquidacion.miercoles },
    { nDiaCorte: 3, nDiaPago: liquidacion.jueves },
    { nDiaCorte: 4, nDiaPago: liquidacion.viernes },
    { nDiaCorte: 5, nDiaPago: liquidacion.sabado },
    { nDiaCorte: 6, nDiaPago: liquidacion.domingo },
  ];
  return daysOfWeek;
}
export const validateEmpty = (liquidacion) => {
  let isEmpty = false;
  const dayOfWeek = [
    'lunes',
    'martes',
    'miercoles',
    'jueves',
    'viernes',
    'sabado',
    'domingo'
  ];

  dayOfWeek.forEach((day) => {
    if (liquidacion[day] === '') {
      isEmpty = true;
      return;
    }
  });
  return isEmpty ? true : false;
}
export const getFacturaTransferencia = (factura) => ([
  {
    nIVA: factura.ivaFac,
    sRegimenFiscal: factura.regimenFiscalFac,
    sUsoCFDI: factura.cfdi,
    sClaveProducto: factura.claveProducto,
    sClaveUnidad: factura.claveUnidad,
    nPeriodoFactura: factura.periocidad,
    sDiasLiquidacion: factura.diasLiquidacion,
    sFormaPago: factura.formaPago,
    sMetodoPago: factura.metodoPago,
    nIdTipoFactura: '2',                  // Comision: 1; Transferencia: 2, Minima: 3
    correos: getFormatEmailFactura(factura.emails)
  }
]);

export const getFacturaByType = (factura, tipo) => ({
  nTipoFactura: tipo,
  sUsoCFDI: factura.cfdi,
  sClaveProducto: factura.claveProducto,
  sClaveUnidad: factura.claveUnidad,
  nPeriodoFactura: factura.periocidad,
  sDiasLiquidacion: factura.diasLiquidacion,
  sFormaPago: factura.formaPago,
  sMetodoPago: factura.metodoPago,
  nIdTipoFactura: tipo,
  correos: getFormatEmailFactura(factura.emails),
});

export const getFormatEmailFactura = (lista = []) => {
  const emailsMap = lista.map((email) => email.email);
  return emailsMap.filter((email) => email !== '');
}


export const cardList = [
  {
    title: 'Datos generales',
    id: 'datosGenerales',
    icon: <AssignmentInd sx={{ fontSize: '70px', color: '#00CD68' }} />
  },
  {
    title: 'Dirección fiscal y contactos',
    id: 'direccion',
    icon: <LocationOn sx={{ fontSize: '70px', color: '#00CD68' }} />
  },
  {
    title: 'Datos bancarios',
    id: 'datosBancarios',
    icon: <AccountBalance sx={{ fontSize: '70px', color: '#00CD68' }} />
  },
  {
    title: 'Datos operativos',
    id: 'datosOperativos',
    icon: <Settings sx={{ fontSize: '70px', color: '#00CD68' }} />
  },
  {
    title: 'Program. CASH IN',
    id: 'cashIn',
    icon: <CreditScore sx={{ fontSize: '70px', color: '#00CD68' }} />
  },
  {
    title: 'Program. CASH OUT',
    id: 'cashOut',
    icon: <AddCard sx={{ fontSize: '70px', color: '#00CD68' }} />
  },
  {
    title: 'Documentos',
    id: 'documentos',
    icon: <DriveFolderUpload sx={{ fontSize: '70px', color: '#00CD68' }} />
  },
  {
    title: 'Factura comisión por transferencia',
    id: 'facturaTransferencia',
    icon: <Receipt sx={{ fontSize: '70px', color: '#00CD68' }} />
  },
  {
    title: 'Ticket',
    id: 'ticket',
    icon: <Receipt sx={{ fontSize: '70px', color: '#00CD68' }} />
  },
  {
    title: 'Cuentas CASH IN',
    id: 'cuentaContableIn-cadena',
    icon: <KeyboardDoubleArrowLeft sx={{ fontSize: '70px', color: '#00CD68' }} />
  },
  {
    title: 'Cuentas CASH OUT',
    id: 'cuentaContableOut-cadena',
    icon: <KeyboardDoubleArrowRight sx={{ fontSize: '70px', color: '#00CD68' }} />
  }
];

export const periodicidades = [
  { value: '1', text: 'Semanal' },
  { value: '2', text: 'Quincenal' },
  { value: '3', text: 'Mensual' },
]

export const daysOfWeek = [
  { value: '0', text: 'Lunes', idName: 'lunes' },
  { value: '1', text: 'Martes', idName: 'martes' },
  { value: '2', text: 'Miércoles', idName: 'miercoles' },
  { value: '3', text: 'Jueves', idName: 'jueves' },
  { value: '4', text: 'Viernes', idName: 'viernes' },
  { value: '5', text: 'Sábado', idName: 'sabado' },
  { value: '6', text: 'Domingo', idName: 'domingo' }
];

export const getDataCash = (cash) => ({
  nCuentaIngresos: +cash.nCuentaIngresos.replaceAll('-', ''),
  nCuentaCostos: +cash.nCuentaCostos.replaceAll('-', ''),
  nCuentaProveedor: +cash.nCuentaProveedor.replaceAll('-', ''),
  nCuentaCliente: +cash.nCuentaCliente.replaceAll('-', ''),
  nIVATraslado: +cash.nIVATraslado.replaceAll('-', ''),
  nIVAAcreditable: +cash.nIVAAcreditable.replaceAll('-', ''),
  nCuentaBancos: +cash.nCuentaBancos.replaceAll('-', '')
});


export const cardListEFR = [
  {
    title: 'Datos generales',
    id: 'datosGenerales-efr',
    icon: <AssignmentInd sx={{ fontSize: '70px', color: '#00CD68' }} />
  },
  {
    title: 'Dirección fiscal y contactos',
    id: 'direccion-efr',
    icon: <LocationOn sx={{ fontSize: '70px', color: '#00CD68' }} />
  },
  {
    title: 'Datos bancarios',
    id: 'datosBancarios-efr',
    icon: <AccountBalance sx={{ fontSize: '70px', color: '#00CD68' }} />
  },
  {
    title: 'Datos operativos',
    id: 'datosOperativos-efr',
    icon: <Settings sx={{ fontSize: '70px', color: '#00CD68' }} />
  },
  {
    title: 'Program. CASH IN',
    id: 'cashIn-efr',
    icon: <CreditScore sx={{ fontSize: '70px', color: '#00CD68' }} />
  },
  {
    title: 'Program. CASH OUT',
    id: 'cashOut-efr',
    icon: <AddCard sx={{ fontSize: '70px', color: '#00CD68' }} />
  },
  {
    title: 'Documentos',
    id: 'documentos-efr',
    icon: <DriveFolderUpload sx={{ fontSize: '70px', color: '#00CD68' }} />
  },
  {
    title: 'Datos facturación',
    id: 'datosFacturacion-efr',
    icon: <ReceiptLong sx={{ fontSize: '70px', color: '#00CD68' }} />
  },
  {
    title: 'Ticket',
    id: 'ticket-efr',
    icon: <Receipt sx={{ fontSize: '70px', color: '#00CD68' }} />
  },
  {
    title: 'Cuentas CASH IN',
    id: 'cuentaContableIn-efr',
    icon: <KeyboardDoubleArrowLeft sx={{ fontSize: '70px', color: '#00CD68' }} />
  },
  {
    title: 'Cuentas CASH OUT',
    id: 'cuentaContableOut-efr',
    icon: <KeyboardDoubleArrowRight sx={{ fontSize: '70px', color: '#00CD68' }} />
  }
];